/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Input from '@comp/Forms/Input';
import s from './CustomInput.scss';

const cx = classNames.bind(s);

const CustomInput = ({
  id,
  name,
  type,
  expand,
  inputClassName,
  containerClassName,
  labelClassName,
  label,
  step,
  input,
  children,
  meta
}) => (
  <div
    className={cx({
      container: true,
      container__expand: true,
      'test__custom-input-container': true,
      [containerClassName]: containerClassName
    })}
  >
    <label
      htmlFor={id}
      className={cx({ label: true, [labelClassName]: labelClassName })}
    >
      {label}
    </label>
    <Input
      id={id}
      name={name}
      type={type}
      expand={expand}
      inputClassName={inputClassName}
      input={input}
      step={step}
    />
    {meta && meta.touched && (
      <span className={cx({ error: true })}>{meta.error}</span>
    )}
    {children}
  </div>
);

CustomInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  step: PropTypes.string,
  type: PropTypes.string,
  expand: PropTypes.bool,
  inputClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  input: PropTypes.shape({}),
  children: PropTypes.node,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool
  })
};

export default CustomInput;
